import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import Intl from '../../../components/Intl';
import Register from 'components/Text/Register';

import media from 'src/styles/media';

const slides = [
  {
    src: require('src/assets/images/home/hero-form-concept-box.svg'),
    mobileSrc: require('src/assets/images/home/mobile_form-concept.svg'),
    subtitle: 'An obsession for detail',
  },
  {
    src: require('src/assets/images/home/hero-time-place-box.svg'),
    mobileSrc: require('src/assets/images/home/mobile_form-place.svg'),
    subtitle: 'The essence of community',
  },
  {
    src: require('src/assets/images/home/hero-time-commitment-box.svg'),
    mobileSrc: require('src/assets/images/home/mobile_form-commitment.svg'),
    subtitle: 'A 50 year legacy',
  },
];

const Hero = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => handleSlide(activeSlide), 3500);
    return () => clearInterval(interval);
  }, [activeSlide]);

  const handleSlide = () => {
    if (activeSlide === slides.length - 1) {
      return setActiveSlide(0);
    } else {
      return setActiveSlide(activeSlide + 1);
    }
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Video
            src="https://player.vimeo.com/external/518753451.hd.mp4?s=653e2177063a67b99646b2609b30c18d4c023d78&amp;profile_id=174"
            autoPlay
            muted
            loop
            playsInline
          />
          <Overlay />
          <Logo
            src={require('src/assets/images/home/hero_taglines_homepage.svg')}
            alt="Smith &amp; Farrow"
          />
          <StyledRegister />
          <Link to="content" smooth offset={-80}>
            <Arrow
              src={require('src/assets/images/icons/arrow-down-new.svg')}
              alt="Scroll Down"
            />
          </Link>
        </Root>
      )}
    </Intl>
  );
};

export default Hero;

const Root = styled.div`
  height: calc(100vh - 104px);
  margin-top: 104px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${require('src/assets/images/home/video_2x.jpg')});
  background-size: cover;
  background-position: center;
  @media (min-width: ${media.tablet}) {
    background-image: none;
    height: 56vw;
  }
  @media (min-width: ${media.desktop}) {
    height: calc(100vh - 174px);
    min-height: 564px;
    margin-top: 174px;
  }
  @media ${media.ipadPro} {
    height: 56vw;
    min-height: none;
    margin-top: 70px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  /* display: none; */
  @media (min-width: ${media.tablet}) {
    display: block;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

const Logo = styled.img`
  display: block;
  width: 80%;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  @media (min-width: ${media.tablet}) {
    width: 450px;
    margin-bottom: 74px;
  }
  @media (min-width: ${media.desktop}) {
    width: 900px;
    margin-bottom: 74px;
  }
`;

const Arrow = styled.img`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 46px;
  height: 13px;
  z-index: 1;
  @media (min-width: ${media.tablet}) {
    bottom: 45px;
  }
  @media (min-width: ${media.desktop}) {
    bottom: 80px;
    cursor: pointer;
  }
  @media (min-width: ${media.ipadPro}) {
    bottom: 45px;
  }
`;

const StyledRegister = styled(Register)`
  position: relative;
  z-index: 2;
`;
