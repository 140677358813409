import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import Intl from 'components/Intl';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Hamburger from './elements/Hamburger';
import MobileMenu from './elements/MobileMenu';

import media from 'src/styles/media';

const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isScrollingDown, setIsScrollingDown] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e) => {
    if (e.target.scrollingElement.scrollTop > 100) {
      setIsScrollingDown(true);
    } else {
      setIsScrollingDown(false);
    }
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <>
          <Root isScrolling={isScrollingDown}>
            <Container isScrolling={isScrollingDown}>
              <Link
                to={`${getLocaleURL()}/`}
                className="logo"
                onClick={() => setIsMenuOpen(false)}
              >
                <Logo
                  src={require('src/assets/images/logos/smith-farrow-logo-dark.svg')}
                  alt="Smith &amp; Farrow"
                  isScrolling={isScrollingDown}
                />
              </Link>
              <StyledHamburger
                active={isMenuOpen}
                handleClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <Links
                style={{
                  transform: isScrollingDown
                    ? 'translateY(0)'
                    : 'translateY(18px)',
                }}
              >
                {/* Menu links */}
                {t.menu.map((item, index) => (
                  <StyledLink
                    to={`${getLocaleURL()}${item.to}`}
                    key={index}
                    active={props.location.pathname === item.to}
                    hidden={locale !== 'en' && item.enOnly}
                  >
                    {item.title}
                  </StyledLink>
                ))}

                {/* Only show Language Switcher on non-English locale */}
                {locale !== 'en' && <LanguageSwitcher />}
              </Links>
            </Container>
            <a
              href="https://rentsmithandfarrow.boffo.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Wrapper>
                <p className="banner black">explore rental homes</p>
              </Wrapper>
            </a>
          </Root>
          <MobileMenu
            active={isMenuOpen}
            links={t.menu}
            closeMenu={() => setIsMenuOpen(false)}
          />
        </>
      )}
    </Intl>
  );
};

export default withRouter(Header);

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px; */
  z-index: 5;
  box-shadow: ${(props) =>
    props.isScrolling
      ? '0 2px 4px 0 rgba(0, 0, 0, 0.15)'
      : '0 2px 4px 0 rgba(0, 0, 0, 0)'};
  transition: 0.3s ease;
  .logo {
    margin-left: 0;
    &::after {
      display: none;
    }
  }

  @media (min-width: ${media.desktop}) {
    box-shadow: ${(props) =>
      props.isScrolling
        ? '0 2px 4px 0 rgba(0, 0, 0, 0.15)'
        : '0 2px 4px 0 rgba(0, 0, 0, 0)'};
  }
`;

const Container = styled.div`
  background-color: white;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  @media (min-width: ${media.desktop}) {
    justify-content: space-between;
    height: 136px;
    max-height: ${(props) => (props.isScrolling ? '80px' : '136px')};
    box-shadow: ${(props) =>
      props.isScrolling
        ? '0 2px 4px 0 rgba(0, 0, 0, 0.15)'
        : '0 2px 4px 0 rgba(0, 0, 0, 0)'};
    transition: 0.3s ease;
    padding: 0 60px;
  }

  @media ${media.ipadPro} {
    justify-content: center;
    height: 70px;
    padding: 0 20px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 34px;
  background-color: ${({ theme }) => theme.color.offWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  p {
    color: black;
  }
  @media (min-width: ${media.tablet}) {
    height: 38px;
  }
  @media (min-width: ${media.desktop}) {
    transition: 0.5s ease;
    &:hover {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const StyledLink = styled(Link)`
  color: black;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.family};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: 2.5px;
  margin-bottom: 0;
  margin-left: 20px;
  position: relative;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  &:last-of-type {
    font-weight: bold;
  }
  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.grey};
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: 0.3s ease-in;
  }
  &:hover::after {
    opacity: 1;
  }
  @media (min-width: 1350px) {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: 2.79px;
    margin-left: 24px;
  }
`;

const Logo = styled.img`
  width: 164px;
  height: 39px;
  @media (min-width: ${media.desktop}) {
    width: 309px;
    height: auto;
    max-width: ${(props) => (props.isScrolling ? '120px' : '180px')};
    transition: 0.3s ease;
  }
  @media (min-width: 1100px) {
    max-width: ${(props) => (props.isScrolling ? '150px' : '200px')};
  }
  @media (min-width: 1200px) {
    max-width: ${(props) => (props.isScrolling ? '180px' : '250px')};
  }
  @media (min-width: 1350px) {
    max-width: ${(props) => (props.isScrolling ? '188px' : '309px')};
  }
  @media ${media.ipadPro} {
    width: 164px;
    height: 39px;
    max-width: none;
  }
`;

const StyledHamburger = styled(Hamburger)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  @media ${media.ipadPro} {
    display: block;
  }
`;

const Links = styled.div`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: flex;
    transition: 0.3s ease-in;
  }
  @media ${media.ipadPro} {
    display: none;
  }
`;
